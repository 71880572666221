// Included Global Variables and Functions
@import "dashlite_variables";

// Included Fonts
/** 01. FONTS */
@import "core/fonts/roboto";
@import "core/fonts/nunito";
@import "core/fonts/poppins";

// Included Bootstarp
@import "extend/bootstrap/variables";
@import "extend/bootstrap/bootstrap";
@import "extend/bootstrap/extend";

// Include Other Vendors
@import "vendors/bundle";

// Include Core Dashlite Style
@import "core/style";

////////////////////////// DO NOT REMOVE ABOVE ///////////////////////

////// GLOBAL INCLUDED
@import "global/style";

//////  INDIVIDUL CONCEPTS
@import "invest/style";

//////  APPLICATONS - Common Uses
@import "apps/asterisk";
@import "apps/reply-comment";
@import "apps/attach";

//////  Apps
@import "apps/messages"; // - Support Messages
@import "apps/inbox"; // - Inbox/ Mail App
@import "apps/file-manager"; // - File Manager
@import "apps/chats"; // - Chats

///////  PREVIEW PURPOSE
@import "core/preview"; // Can be remove on real projoect

///////  UI/MODE OVERRIDE
@import "core/mode-override";

///////  DARK MODE SKIN
@import "core/dark-skin";

/** 08. CUSTOM */
@import "./custom";

/*! END @iO */
////////////////////////// END STYLESHEET ////////////////////////////
