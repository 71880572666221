// Roboto
@font-face {
  font-family: "Poppins";
  src: url("#{$font-url}/Poppins-Light.eot");
  src: local("Poppins Light"), local("Poppins-Light"),
    url("#{$font-url}/Poppins-Light.eot#iefix") format("embedded-opentype"),
    url("#{$font-url}/Poppins-Light.woff2") format("woff2"), url("#{$font-url}/Poppins-Light.woff") format("woff"),
    url("#{$font-url}/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("#{$font-url}/Poppins-Regular.eot");
  src: local("Poppins"), local("Poppins-Regular"),
    url("#{$font-url}/Poppins-Regular.eot#iefix") format("embedded-opentype"),
    url("#{$font-url}/Poppins-Regular.woff2") format("woff2"), url("#{$font-url}/Poppins-Regular.woff") format("woff"),
    url("#{$font-url}/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("#{$font-url}/Poppins-Medium.eot");
  src: local("Poppins Medium"), local("Poppins-Medium"),
    url("#{$font-url}/Poppins-Medium.eot#iefix") format("embedded-opentype"),
    url("#{$font-url}/Poppins-Medium.woff2") format("woff2"), url("#{$font-url}/Poppins-Medium.woff") format("woff"),
    url("#{$font-url}/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("#{$font-url}/Poppins-Bold.eot");
  src: local("Poppins Bold"), local("Poppins-Medium"),
    url("#{$font-url}/Poppins-Bold.eot#iefix") format("embedded-opentype"),
    url("#{$font-url}/Poppins-Bold.woff2") format("woff2"), url("#{$font-url}/Poppins-Bold.woff") format("woff"),
    url("#{$font-url}/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
