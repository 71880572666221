.spinner {
  display: block;
  position: fixed;
  z-index: 1031; /* High z-index so it is on top of the page */
  top: 50%;
  right: 50%; /* or: left: 50%; */
  margin-top: -20px; /* half of the elements height */
  margin-right: -20px; /* half of the elements width */
}

.project-progress {
  margin-bottom: 0px !important;
}
::-webkit-resizer {
  display: none;
}
// course-wizard test table row
.wizard .card .card-inner .nk-tb-list .question-type-table .nk-tb-col:first-child {
  width: 100px !important;
}
.title {
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: normal !important;
  line-height: 1.2; /* Adjust this value as needed */
}
.nk-reply-header {
  margin-bottom: -5px !important;
}
.user-name {
  margin-left: 0.5rem !important;
}
.reply-seprater-line {
  margin-top: 15px;
  margin-left: 2.7rem;
  margin-right: 2.7rem;
  border-bottom: 1px solid #e5e9f2;
}
// matrix table css

.min-w-200 {
  min-width: 200px !important;
}
.border-light-gray {
  border: 1px solid #dbdfea;
}
.border-light-gray-right {
  border-right: 1px solid #dbdfea;
}
.border-light-gray-left {
  border-left: 1px solid #dbdfea;
}
.overflow-x-auto {
  overflow-x: auto !important;
}
.overflow-auto {
  overflow: scroll !important;
}
.cursor {
  cursor: pointer;
}
.min-h-calc_100vh_-_220 {
  max-height: calc(100vh - 220px) !important;
}
.matrix-table .nk-tb-head {
  position: sticky !important;
  top: 0px !important;
  background-color: white !important;
  z-index: 2;
  font-weight: 500;
  color: #364a63;
}
.matrix-table .nk-tb-head .nk-tb-col {
  min-width: 150px !important ;
}
.matrix-table .nk-tb-head .nk-tb-col:first-child {
  position: sticky !important;
  top: 0px !important;
  left: 0px !important;
  background-color: white !important;
  z-index: 1;
}

.matrix-table .nk-tb-head .nk-tb-col:nth-child(2) {
  min-width: 88px !important ;
}
.matrix-table .t-body .nk-tb-col:first-child {
  position: sticky !important;
  top: 0px !important;
  left: 0px !important;
  background-color: white !important;
  z-index: 1;

  font-weight: 500;
  color: #364a63;
}
// matrix table scroll bar
.matrix-table #style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: white;
}

.matrix-table #style-3::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
}

.matrix-table #style-3::-webkit-scrollbar-thumb {
  background-color: #c0c3cc;
}
#custome-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: white;
}
#custome-scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
}
#custome-scroll::-webkit-scrollbar-thumb {
  background-color: #c0c3cc;
}

// client training
.user-training-course-details .project-progress {
  max-width: 100% !important;
}
// pdf viewer
.h-calc_100vh_-_316 {
  height: calc(100vh - 316px);
}
.cursor-pointer {
  cursor: pointer !important;
}
.training-card .card-inner {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.course-details {
  .course-progess-bar {
    height: 1.5rem;
    .progress-bar {
      border-radius: 8px;
    }
  }
  ul,
  ol {
    padding-left: 16px !important;
  }
  li {
    list-style-type: disc !important;
  }
  .react-transform-wrapper {
    width: auto !important;
  }
  .react-transform-component {
    // display: contents !important;
    padding: 4px;
  }
  .slick-list {
    max-width: 750px !important;
  }
}
.course-complete-certificate {
  min-height: 580px;
}
.letter-spacing-1 {
  letter-spacing: 1px;
}

.max-w-512 {
  max-width: 512px;
}
.line-height-18 {
  line-height: 18px !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.w-250 {
  width: 250px !important;
}
.w-72 {
  width: 72px !important;
}
.pe-sm-36 {
  padding-right: 36px !important;
  @media only screen and (max-width: 576px) {
    padding-right: 16px !important;
  }
}
.border-none-asm {
  @media only screen and (max-width: 576px) {
    border: none !important;
  }
}
.ps-sm-36 {
  padding-left: 36px !important;
  @media only screen and (max-width: 576px) {
    padding-left: 16px !important;
  }
}
.course-details-page .container-fluid {
  padding: 0px !important;
}
.course-details-page .nk-content {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.course-card {
  position: fixed !important;
  width: 374px !important;

  margin-top: -170px;
  @media only screen and (max-width: 991px) {
    margin-top: -88px;
    position: static !important;
    width: 374px !important;
    .card-button {
      position: fixed !important;
      z-index: 50;
      bottom: 24px !important;
      left: 24px !important;
      right: 24px !important;
      margin: auto;
    }
  }
  @media only screen and (max-width: 768px) {
    margin-top: 0px;
    @media only screen and (max-width: 576px) {
      width: 100% !important;
    }
  }
}
.fs-0_775rem {
  font-size: 0.775rem !important;
}
.fs-56 {
  font-size: 56px !important;
  @media only screen and (max-width: 576px) {
    font-size: 42px !important;
  }
}
.min-h-292 {
  min-height: 292px !important;
}
.font-popins {
  font-family: "Poppins", sans-serif !important;
}
.bg-tranperent-black {
  background-color: #0000007a;
}
.calender-input-position {
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  z-index: 1;
}
.word-wrap {
  word-wrap: break-word;
}
.react-datepicker-popper {
  z-index: 2 !important;
}
.role-filter,
.location-filter,
.course-matrix-filter,
.add-face-event-modal,
.add-resource-modal,
.mark-as-complete,
.edit-staff-training {
  .btn-close {
    color: white !important;
    opacity: 1 !important;
  }
}
.bg-purple {
  background-color: #7b68ee !important;
}
.round-40 {
  min-width: 40px !important;
  height: 40px !important;
}
.react-select__single-value {
  font-family: "Poppins", sans-serif !important;
}
.active-dot {
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 100%;
  position: absolute;
  right: 6px;
  top: 6px;
}
.active-dot-primary {
  width: 8px;
  height: 8px;
  background-color: #1a7dff;
  border-radius: 100%;
  position: absolute;
  right: 6px;
  top: 6px;
}
// course chapter details css --------------------->

.course-chapter-details p,
.course-chapter-details ul li,
.course-chapter-details ol li {
  font-size: 20px !important;
  //  font-weight: 300 !important;
  line-height: 35px !important;
}
// Button---------------------//
.custom-outline-btn {
  color: #006eff;
  background-color: #e7eef7;
  border: 1px solid;
  border-color: #a7c1e1;
  cursor: pointer;
  position: relative;
  letter-spacing: 0.02em;
  display: inline-flex;
  align-items: center;
  padding: 7px 18px;
  font-weight: 500;
  border-radius: 4px;
  line-height: 20px;
}
.min-h-88 {
  min-height: 120px !important;
}
.custom-solid-btn {
  &-lg {
    padding: 11px 24px !important;
    color: white !important;
    background-color: #7b68ee !important;
    border: none !important;
    cursor: pointer;
    position: relative;
    letter-spacing: 0.02em;

    align-items: center;
    // padding: 11px 24px ;
    font-weight: 500;
    border-radius: 4px;
    line-height: 20px !important;
    font-weight: 700;
    box-shadow: 0px 7px 12px 1px rgb(123 104 238 / 50%) !important;
    &:hover {
      background-color: #006eff !important;
      box-shadow: 0px 7px 12px 1px rgb(29 84 152 / 48%) !important;
    }
  }
  &-sm {
    padding: 7px 18px !important ;
    color: white !important;
    background-color: #7b68ee !important;
    border: none !important;
    cursor: pointer;
    position: relative;
    letter-spacing: 0.02em;

    align-items: center;
    // padding: 11px 24px ;
    font-weight: 500;
    border-radius: 4px;
    line-height: 20px !important;
    font-weight: 700;
    box-shadow: 0px 7px 12px 1px rgb(123 104 238 / 50%) !important;
    &:hover {
      background-color: #006eff !important;
      box-shadow: 0px 7px 12px 1px rgb(29 84 152 / 48%) !important;
    }
  }
}
.slick-track {
  margin-left: 0 !important;
}
.iconSize18 {
  font-size: 18px !important;
}
.wizard-margin {
  flex-grow: 0 !important;
  padding-left: 20px;
  padding-right: 20px;
}
.nk-sales-ck {
  &.sales-revenue-lg {
    height: 108px !important;
  }
}
.ftf .nk-sales-ck {
  height: 60px !important;
}
.sEvlc {
  z-index: 100 !important;
  background-color: #006eff !important;
}
.min-h-400 {
  min-height: 400px !important;
}
.course-details-page .list-checked li:before {
  position: absolute;
  left: -4px;
  top: 4px;
  margin-right: 4px !important;
  height: 24px !important;
  width: 24px !important;
  content: url("../../images/icon-check-green.svg") !important;
}
.resource-list-base {
  padding-bottom: 10px !important;
  display: block !important;
}
.flex-grow-1 {
  flex-grow: 1;
}

.nk-upload-files-filelist {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(300px, 1fr)
  ); /* Each column minimum 300px wide, fill available space */
  gap: 10px; /* Adjust the gap between items */
  margin-top: 17px;
}

.nk-upload-item-filelist {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #e5e9f2;
}

.nk-upload-files-replyfilelist {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(300px, 1fr)
  ); /* Each column minimum 300px wide, fill available space */
  gap: 10px; /* Adjust the gap between items */
  // margin-top: 17px;
}

.nk-upload-item-replyfilelist {
  display: flex;
  align-items: center;
  justify-content: center; /* Center content horizontally */
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #e5e9f2;
  box-sizing: border-box; /* Include padding and border in the box size */
  min-width: 0; /* Allow items to shrink if needed */
}

// image chnages----------->
.course-details p img {
  height: auto;
  object-fit: contain;
}

// li:has(> a.active) { /* styles to apply to the li tag */ }
.course-details strong:has(> iframe) {
  max-width: 560px !important;
  max-height: 314px !important;
  margin: auto !important;
  display: block !important;
  padding: 0px !important;

  iframe {
    width: 100% !important;
    min-height: 100% !important;
    display: block;
  }
}
.course-details p:has(> iframe),
.course-details span:has(> iframe) {
  max-width: 560px !important;
  max-height: 314px !important;
  margin: auto !important;
  display: block !important;
  padding: 0px !important;

  iframe {
    width: 100% !important;
    min-height: 100% !important;
    display: block;
  }
}

.course-chapter-details .table-div {
  overflow-x: auto !important;
  table {
    width: 700px !important;
  }
}
.face-to-face-meetings + .card-inner {
  overflow-x: auto !important;
  // .nk-tb-ulist  {
  //     width: max-content !important;
  //     // background-color: #1a7dff !important;
  //     }
}
.nk-files-view-list .nk-file:not(:last-child) > div {
  border-bottom: none !important;
}
.mb-70 {
  margin-bottom: 70px !important;
}

.react-datepicker {
  border: none !important;
}

.react-datepicker__header {
  background-color: #ffffff !important;
  border-bottom: none !important;
}
// .react-datepicker__time-container {
//   width: 220px !important;
// }
.react-datepicker__time-box {
  width: 220px !important;
}
.react-datepicker__time-list {
  width: 220px !important;
}
.react-datepicker__time-container {
  width: 200px !important;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #b9deff !important;
  color: #216ba5 !important;
  font-weight: bold;
}
.fc-event {
  overflow: hidden !important;
  width: 90%;
  #default-38 {
    width: 100%;
  }
}

.w85 {
  width: 85%;
}
.privacy-page {
  .fs-30 {
    font-size: 30;
  }
  .w15 {
    width: 15%;
  }
  .w20 {
    width: 20%;
  }
  .w60 {
    width: 60%;
  }
  .TableofContents {
    font-size: 20px;
    color: rgb(109, 108, 108);
  }
  .container {
    padding-left: 80px;
    padding-right: 80px;
  }
  .b {
    list-style-type: disc;
  }

  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
    padding-left: 12px;
  }

  td,
  th {
    text-align: start;
    vertical-align: top;
    padding: 8px;
  }

  .c {
    margin: 0;
    padding-left: 16px;
  }

  .d {
    width: 70%;
    margin: 0 auto;
    padding: 10px;
    overflow-x: scroll;
  }

  .l {
    font-size: larger;
    font-weight: bold;
  }
  .image {
    display: flex;
    align-items: end;
    width: fit-content;
    margin: 0 auto;
    gap: 5px;
  }

  img {
    border-radius: 50%;
  }

  @media only screen and (max-width: 1024px) {
    .container {
      padding-left: 80px;
      padding-right: 80px;
    }
  }
  @media only screen and (max-width: 768px) {
    .container {
      padding-left: 40px;
      padding-right: 40px;
    }
    .d {
      width: 92%;
    }
  }
  @media only screen and (max-width: 576px) {
    .container {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}

.help {
  .container {
    padding-left: 80px;
    padding-right: 80px;
  }
  @media only screen and (max-width: 1024px) {
    .container {
      padding-left: 80px;
      padding-right: 80px;
    }
  }
  @media only screen and (max-width: 768px) {
    .container {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
  @media only screen and (max-width: 576px) {
    .container {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}

.termsCondition {
  .container {
    padding-left: 80px;
    padding-right: 80px;
  }

  .tbl {
    overflow-x: scroll;
  }

  th,
  td {
    width: 50%;
    align-self: self-start;
  }

  th {
    display: flex;
    align-items: start;
  }

  .a {
    list-style-type: disc;
  }

  @media only screen and (max-width: 1024px) {
    .container {
      padding-left: 80px;
      padding-right: 80px;
    }
  }

  @media only screen and (max-width: 768px) {
    .container {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  @media only screen and (max-width: 576px) {
    .container {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  @media only screen and (max-width: 1024px) {
    .tbl {
      padding-left: 80px;
      padding-right: 80px;
    }
  }

  @media only screen and (max-width: 768px) {
    .tbl {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  @media only screen and (max-width: 576px) {
    .tbl {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .course-chapter-details table {
    width: 90% !important;
  }
  .course-details p:has(> br) {
    br {
      display: none !important;
    }
  }
  .course-list-view .card-inner {
    overflow-x: auto;
  }
  .certificate-list-table .card-inner {
    overflow-x: auto;
  }
  .frequency-table .card-inner {
    overflow-x: auto;
  }
}

@media (max-width: 768px) {
  .custom-margin-top {
    margin-top: 1rem;
  }
}
.border-table {
  border-collapse: collapse;
}
.border-table th,
td,
tr {
  border: 1px solid black;
}
.border-table tr {
  text-align: center;
}
.border-table td {
  padding-top: 10px;
  padding-bottom: 10px;
}

.custom-height {
  .react-select__menu-list {
    max-height: 215px !important;
  }
}
.css-at12u2-loadingIndicator {
  margin-right: 20px !important;
}
.w-200 {
  width: 400px;
}

.pending-msg-count {
  position: absolute;
  top: -6px;
  right: -4px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-weight: bold;
}

.pending-msg-count-badge {
  position: absolute;
  top: 7px;
  right: 39px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-weight: bold;
}
.break-word {
  word-wrap: break-word;
}
