$rdl-icon-font-family: $nk-dashlite-font;
$rdl-input-bg: #fff !default;
$rdl-input-disabled-bg: #eee !default;
$rdl-input-border: #ccc !default;
$rdl-input-border-active: darken($rdl-input-border, 25%) !default;
$rdl-input-border-hover: darken($rdl-input-border, 12%) !default;
$rdl-input-color: #333 !default;
$rdl-btn-bg: $rdl-input-bg !default;
$rdl-btn-bg-active: darken($rdl-btn-bg, 10%) !default;
$rdl-btn-border: $rdl-input-border !default;
$rdl-btn-border-active: $rdl-input-border-active !default;
$rdl-btn-border-hover: $rdl-input-border-hover !default;
$rdl-btn-color: #333 !default;
$rdl-line-height: 1.42857 !default;
$rdl-desktop-min-width: 768px !default;
$rdl-control-width: 256px !default;

.react-dual-listbox {
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  input,
  select {
    &:disabled {
      cursor: not-allowed;
      background: $rdl-input-disabled-bg;
    }
  }

  button,
  select {
    font-family: inherit;
    line-height: $rdl-line-height;
  }

  // Set minimum height to prevent boxes from resizing on mobile
  select {
    min-height: 146px;
  }
}
.dual-listbox{
  display: flex;
  flex-direction: column;
  gap:1rem;
  &__search {
    @extend .form-control;
    max-width: $rdl-control-width;
    &--hidden{
      display: none;
    }
  }
}

.rdl-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  @media (min-width: $rdl-desktop-min-width) {
    flex-direction: row;
  }
}

.rdl-hidden-input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

.rdl-list-box {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  align-self: stretch;
}

.rdl-filter {
  margin-bottom: 10px;

  &:focus:not(:disabled) {
    border-color: $rdl-input-border-active;
    outline: 0;
  }
}

.rdl-control-container {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}

.rdl-control-label {
  position: absolute;
  padding: 0 4px 4px;
  clip: rect(0 0 0 0);

  .rdl-has-header & {
    position: static;
    clip: auto;
  }
}

.rdl-control {
  flex: 1 0 auto;

  &:focus:not(:disabled) {
    border-color: $rdl-input-border-active;
    outline: 0;
  }

  optgroup {
    font: inherit;
    font-weight: 700;
  }
}

.rdl-actions {
  display: flex;
  flex: 0 0 auto;
  margin: 1rem 0;
  gap:.75rem;

  @media (min-width: $rdl-desktop-min-width) {
    flex-direction: column;
    width: auto;
    margin: 0 1rem;
  }
}

.rdl-actions-to-selected,
.rdl-actions-to-available {
  display: flex;
  gap:.5rem;
  justify-content: center;
  @media (min-width: $rdl-desktop-min-width) {
    flex-direction: column;
  }
}

.rdl-btn {
  color: $base-color;
  @extend .btn;
  @extend .btn-light;
}

.rdl-no-options {
  height: 100%;
  padding: 4px 6px;
}

.rdl-icons-icon {
  .rdl-icon {
    font-family: $rdl-icon-font-family;
    font-weight: 900;
    font-size: 1rem;
  }

  .rdl-icon-move-all-to-selected::before,
  .rdl-icon-move-bottom::before {
    content: $ni-chevrons-down;
  }

  .rdl-icon-move-to-selected::before,
  .rdl-icon-move-down::before {
    content: $ni-chevron-down;
  }

  .rdl-icon-move-to-available::before,
  .rdl-icon-move-up::before {
    content: $ni-chevron-up;
  }

  .rdl-icon-move-all-to-available::before,
  .rdl-icon-move-top::before {
    content: $ni-chevrons-up;
  }

  // Larger screens will arrange the boxes as columns, so "left" and "right" icons apply
  @media (min-width: $rdl-desktop-min-width) {
    .rdl-icon-move-to-available::before {
      content: $ni-chevron-left;
    }

    .rdl-icon-move-all-to-available::before {
      content: $ni-chevrons-left;
    }

    .rdl-icon-move-to-selected::before {
      content: $ni-chevron-right;
    }

    .rdl-icon-move-all-to-selected::before {
      content: $ni-chevrons-right;
    }

    // Change directions when right-to-left
    &[dir="rtl"] {
      .rdl-icon-move-to-available::before {
        content: $ni-chevron-right;
      }

      .rdl-icon-move-all-to-available::before {
        content: $ni-chevrons-right;
      }

      .rdl-icon-move-to-selected::before {
        content: $ni-chevron-left;
      }

      .rdl-icon-move-all-to-selected::before {
        content: $ni-chevrons-left;
      }
    }
  }
}

.rdl-icons-text {
  .rdl-icon {
    font-size: .875rem;
  }

  .rdl-icon-move-all-to-selected::before,
  .rdl-icon-move-bottom::before {
    content: 'Add All';
  }

  .rdl-icon-move-to-selected::before,
  .rdl-icon-move-down::before {
    content: 'Add';
  }

  .rdl-icon-move-to-available::before,
  .rdl-icon-move-up::before {
    content: 'Remove';
  }

  .rdl-icon-move-all-to-available::before,
  .rdl-icon-move-top::before {
    content: 'Remove All';
  }
  
}

.rdl-icons-native {
  .rdl-icon-move-all-to-selected::before,
  .rdl-icon-move-bottom::before {
    content: "⮇";
  }

  .rdl-icon-move-to-selected::before,
  .rdl-icon-move-down::before {
    content: "🠣";
  }

  .rdl-icon-move-to-available::before,
  .rdl-icon-move-up::before {
    content: "🠡";
  }

  .rdl-icon-move-all-to-available::before,
  .rdl-icon-move-top::before {
    content: "⮅";
  }

  // Larger screens will arrange the boxes as columns, so "left" and "right" icons apply
  @media (min-width: $rdl-desktop-min-width) {
    .rdl-icon-move-to-available::before {
      content: "⭠";
    }

    .rdl-icon-move-all-to-available::before {
      content: "⮄";
    }

    .rdl-icon-move-to-selected::before {
      content: "⭢";
    }

    .rdl-icon-move-all-to-selected::before {
      content: "⮆";
    }

    // Change directions when right-to-left
    &[dir="rtl"] {
      .rdl-icon-move-to-available::before {
        content: "⭢";
      }

      .rdl-icon-move-all-to-available::before {
        content: "⮆";
      }

      .rdl-icon-move-to-selected::before {
        content: "⭠";
      }

      .rdl-icon-move-all-to-selected::before {
        content: "⮄";
      }
    }
  }
}


.react-dual-listbox {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.react-dual-listbox * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.react-dual-listbox input:disabled,
.react-dual-listbox select:disabled {
  background: #eee;
  cursor: not-allowed;
}
.react-dual-listbox button,
.react-dual-listbox select {
  line-height: 1.42857;
  font-family: inherit;
}

.rdl-selected:focus-visible {
  border: none;
}

.rdl-filter,
.rdl-control {
  display: block;
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 8px 12px;
  width: 100%;
  color: #333;
  font-size: 14px;
}

.rdl-filter {
  @extend .form-control;
  max-width: $rdl-control-width;
  margin-bottom: 10px !important;
  &--hidden{
    display: none;
  }
}

.rdl-control-container {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}

.rdl-control-label {
  font-size: 1rem;
  font-weight: $fw-medium;
  padding: .5rem 1rem;
  border-left: 1px solid $border-light;
  border-right: 1px solid $border-light;
  border-top: 1px solid $border-light;
  border-radius: $input-border-radius $input-border-radius 0 0;
  margin-bottom: 0 !important;
  width: 100%;
}
.rdl-control-label.rdl-sr-only {
  position: absolute;
  clip: rect(0 0 0 0);
}

.rdl-control {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  border: 1px solid $border-light;
  height: 300px;
  overflow-y: auto;
  padding: 0;
  width: $rdl-control-width;
  margin-top: 0;
  border-radius: 0 0 $input-border-radius $input-border-radius;
  &:focus:not(:disabled) {
    outline: none;
    border-color: $border-light;
  }
}
.rdl-control > option {
  display: block;
  padding: .5rem 1rem;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  border-bottom: 1px solid $border-light;
  transition: background-color 0.2s ease;
  &:hover{
    @extend .bg-lighter;
  }
  &:active,
  &:focus {
    @extend .bg-primary-dim;
    outline: none;
    background: transparent !important;
  }
}
.rdl-control optgroup {
  font: inherit;
  font-weight: 700;
}

.rdl-actions {
  display: inline-flex;
  flex-direction: column;
}

.rdl-move:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}


// .rdl-align-top .rdl-available {
//   margin-right: 10px;
// }
// .rdl-align-top .rdl-selected {
//   margin-left: 10px;
// }
// .rdl-align-top .rdl-control {
//   border-top-left-radius: 0;
//   border-top-right-radius: 0;
// }
// .rdl-align-top .rdl-actions-left,
// .rdl-align-top .rdl-actions-right {
//   -webkit-box-orient: horizontal;
//   -webkit-box-direction: normal;
//       -ms-flex-direction: row;
//           flex-direction: row;
//   margin: 0;
// }
// .rdl-align-top .rdl-list-box .rdl-move {
//   -webkit-box-flex: 0;
//       -ms-flex: 0 1 50%;
//           flex: 0 1 50%;
// }
// .rdl-align-top .rdl-list-box .rdl-move:first-child {
//   margin-bottom: 0;
//   border-right: 0;
//   border-top-right-radius: 0;
//   border-bottom-left-radius: 0;
//   border-bottom-right-radius: 0;
// }
// .rdl-align-top .rdl-list-box .rdl-move:last-child {
//   border-top-left-radius: 0;
//   border-bottom-left-radius: 0;
//   border-bottom-right-radius: 0;
// }