$icomoon-font-family: "Nioicon" !default;
$icomoon-font-path: $nk-dashlite-font-url !default;

$ni-discord: unquote('"\\ed34"');
$ni-discord-fill: unquote('"\\ed33"');
$ni-discord-msg: unquote('"\\ed88"');
$ni-line: unquote('"\\ed46"');
$ni-line-fill: unquote('"\\ed45"');
$ni-line-alt: unquote('"\\ed44"');
$ni-line-alt-fill: unquote('"\\ed43"');
$ni-tiktok: unquote('"\\ed66"');
$ni-tiktok-fill: unquote('"\\ed65"');
$ni-tiktok-alt: unquote('"\\ed64"');
$ni-tiktok-alt-fill: unquote('"\\ed63"');
$ni-x: unquote('"\\ed6f"');
$ni-x-fill: unquote('"\\ed87"');
$ni-x-round-fill: unquote('"\\ed70"');
$ni-instagram-fill: unquote('"\\ed7f"');
$ni-threads: unquote('"\\ed60"');
$ni-threads-fill: unquote('"\\ed5f"');
$ni-twitch: unquote('"\\ed6e"');
$ni-twitch-fill: unquote('"\\ed6d"');
$ni-tailwind: unquote('"\\ed20"');
$ni-wechat: unquote('"\\ed79"');
$ni-wechat-fill: unquote('"\\ed78"');
$ni-wechat-alt-fill: unquote('"\\ed77"');
$ni-graphql: unquote('"\\ecf3"');
$ni-node-js: unquote('"\\ecf4"');
$ni-nioboard: unquote('"\\ecf2"');
$ni-svelte: unquote('"\\ecf6"');
$ni-typescript: unquote('"\\ecf7"');
$ni-vue: unquote('"\\ecf8"');
$ni-linux: unquote('"\\ecec"');
$ni-b-chrome: unquote('"\\ec63"');
$ni-b-firefox: unquote('"\\ec94"');
$ni-b-edge: unquote('"\\ec64"');
$ni-b-ie: unquote('"\\ec95"');
$ni-b-opera: unquote('"\\ec96"');
$ni-b-safari: unquote('"\\ec97"');
$ni-b-uc: unquote('"\\ec99"');
$ni-google-play-store-alt: unquote('"\\ed02"');
$ni-node: unquote('"\\ecf5"');
$ni-amazon-full: unquote('"\\ed89"');
$ni-discord-full: unquote('"\\ed8a"');
$ni-dribbble-full: unquote('"\\ed8b"');
$ni-facebook-full: unquote('"\\ed8c"');
$ni-google-full: unquote('"\\ed8d"');
$ni-googlemaps-full: unquote('"\\ed8e"');
$ni-instagram-full: unquote('"\\ed8f"');
$ni-linkedin-full: unquote('"\\ed90"');
$ni-microsoft-full: unquote('"\\ed91"');
$ni-pinterest-full: unquote('"\\ed92"');
$ni-skype-full: unquote('"\\ed93"');
$ni-spotify-full: unquote('"\\ed94"');
$ni-vimeo-full: unquote('"\\ed95"');
$ni-vine-full: unquote('"\\ed96"');
$ni-whatsapp-full: unquote('"\\ed97"');
$ni-windows-full: unquote('"\\ed98"');
$ni-youtube-full: unquote('"\\ed99"');
$ni-curve-down-right-fill: unquote('"\\ed7b"');
$ni-curve-right-down-fill: unquote('"\\ed7c"');
$ni-curve-right-up-fill: unquote('"\\ed7d"');
$ni-curve-up-right-fill: unquote('"\\ed7e"');
$ni-loader-fill: unquote('"\\ed80"');
$ni-pwa-fill: unquote('"\\ed81"');
$ni-pwa: unquote('"\\ed82"');
$ni-question-fill: unquote('"\\ed83"');
$ni-text2-fill: unquote('"\\ed84"');
$ni-wifi-fill: unquote('"\\ed85"');
$ni-wifi-off-fill: unquote('"\\ed86"');
$ni-app-development: unquote('"\\ed21"');
$ni-design-tool: unquote('"\\ed42"');
$ni-web-development: unquote('"\\ed76"');
$ni-img-alt: unquote('"\\ed3d"');
$ni-brain-fill: unquote('"\\ed23"');
$ni-brain: unquote('"\\ed24"');
$ni-briefcase-fill: unquote('"\\ed25"');
$ni-button-fill: unquote('"\\ed26"');
$ni-button: unquote('"\\ed27"');
$ni-carousel-fill: unquote('"\\ed28"');
$ni-carousel: unquote('"\\ed29"');
$ni-cart-alt-fill: unquote('"\\ed2a"');
$ni-cart-alt: unquote('"\\ed2b"');
$ni-checkbox-checked: unquote('"\\ed2c"');
$ni-checkbox: unquote('"\\ed2d"');
$ni-cookie-fill: unquote('"\\ed2e"');
$ni-cookie: unquote('"\\ed2f"');
$ni-copy-page-fill: unquote('"\\ed30"');
$ni-copy-page: unquote('"\\ed31"');
$ni-create-icon: unquote('"\\ed32"');
$ni-create-icon-fill: unquote('"\\ed7a"');
$ni-teeth-fill: unquote('"\\ed35"');
$ni-teeth: unquote('"\\ed36"');
$ni-edit-profile-fill: unquote('"\\ed37"');
$ni-edit-profile: unquote('"\\ed38"');
$ni-fastfood-fill: unquote('"\\ed39"');
$ni-fastfood: unquote('"\\ed3a"');
$ni-form-validation-fill: unquote('"\\ed3b"');
$ni-form-validation: unquote('"\\ed3c"');
$ni-home-new: unquote('"\\ed3e"');
$ni-home-new-fill: unquote('"\\ed3f"');
$ni-input-fill: unquote('"\\ed40"');
$ni-input: unquote('"\\ed41"');
$ni-lung-fill: unquote('"\\ed47"');
$ni-lung: unquote('"\\ed48"');
$ni-plus-alt-fill: unquote('"\\ed4b"');
$ni-plus-alt: unquote('"\\ed4c"');
$ni-progress-bar-fill: unquote('"\\ed4d"');
$ni-progress-bar: unquote('"\\ed4e"');
$ni-radio-checked: unquote('"\\ed4f"');
$ni-radio: unquote('"\\ed50"');
$ni-request-fill: unquote('"\\ed51"');
$ni-request: unquote('"\\ed52"');
$ni-lock-question-fiil: unquote('"\\ed53"');
$ni-lock-question: unquote('"\\ed54"');
$ni-stepper-fill: unquote('"\\ed57"');
$ni-stepper: unquote('"\\ed58"');
$ni-system-status-fill: unquote('"\\ed59"');
$ni-system-status: unquote('"\\ed5a"');
$ni-terms-conditions-fill: unquote('"\\ed5b"');
$ni-terms-conditions: unquote('"\\ed5c"');
$ni-masks-fill: unquote('"\\ed5d"');
$ni-masks: unquote('"\\ed5e"');
$ni-ticket-off-fill: unquote('"\\ed61"');
$ni-ticket-off: unquote('"\\ed62"');
$ni-timeline-fill: unquote('"\\ed67"');
$ni-timeline: unquote('"\\ed68"');
$ni-toasts-fill: unquote('"\\ed69"');
$ni-toasts: unquote('"\\ed6a"');
$ni-toggle-fill: unquote('"\\ed6b"');
$ni-toggle: unquote('"\\ed6c"');
$ni-view-x2-alt: unquote('"\\ed71"');
$ni-view-x4-alt: unquote('"\\ed72"');
$ni-view-x6-alt: unquote('"\\ed73"');
$ni-wallet-new-fill: unquote('"\\ed74"');
$ni-wallet-new: unquote('"\\ed75"');
$ni-bugs-alt-fill: unquote('"\\ecf9"');
$ni-bugs-alt: unquote('"\\ecfa"');
$ni-bulb-alt-fill: unquote('"\\ecfb"');
$ni-bulb-alt: unquote('"\\ecfc"');
$ni-chat-msg-fill: unquote('"\\ecfd"');
$ni-chat-msg: unquote('"\\ecfe"');
$ni-diamond-fill: unquote('"\\ecff"');
$ni-diamond: unquote('"\\ed00"');
$ni-file-code-fill: unquote('"\\ed01"');
$ni-laptop-fill: unquote('"\\ed03"');
$ni-mail-alt: unquote('"\\ed04"');
$ni-newspaper-fill: unquote('"\\ed05"');
$ni-newspaper: unquote('"\\ed06"');
$ni-pie-2-fill: unquote('"\\ed07"');
$ni-pie-2: unquote('"\\ed08"');
$ni-presentation-fill: unquote('"\\ed09"');
$ni-presentation: unquote('"\\ed0a"');
$ni-projector-screen-fill: unquote('"\\ed0b"');
$ni-projector-screen: unquote('"\\ed0c"');
$ni-quote-sq-left-fill: unquote('"\\ed0d"');
$ni-quote-sq-left: unquote('"\\ed0e"');
$ni-quote-sq-right-fill: unquote('"\\ed0f"');
$ni-quote-sq-right: unquote('"\\ed10"');
$ni-send-fill: unquote('"\\ed11"');
$ni-setting-question-fill: unquote('"\\ed12"');
$ni-setting-question: unquote('"\\ed13"');
$ni-support-fill: unquote('"\\ed14"');
$ni-support: unquote('"\\ed15"');
$ni-traffic-signal-fill: unquote('"\\ed16"');
$ni-traffic-signal: unquote('"\\ed17"');
$ni-tree-structure-fill: unquote('"\\ed18"');
$ni-tree-structure: unquote('"\\ed19"');
$ni-trophy-fill: unquote('"\\ed1a"');
$ni-trophy: unquote('"\\ed1b"');
$ni-star-award: unquote('"\\ed56"');
$ni-star-award-fill: unquote('"\\ed55"');
$ni-award-fill: unquote('"\\ed22"');
$ni-medal: unquote('"\\ed4a"');
$ni-medal-fill: unquote('"\\ed49"');
$ni-user-group-fill: unquote('"\\ed1c"');
$ni-user-group: unquote('"\\ed1d"');
$ni-user-switch-fill: unquote('"\\ed1e"');
$ni-user-switch: unquote('"\\ed1f"');
$ni-centos: unquote('"\\ece6"');
$ni-covid: unquote('"\\ece7"');
$ni-fedora: unquote('"\\ece8"');
$ni-hot-fill: unquote('"\\ece9"');
$ni-hot: unquote('"\\ecea"');
$ni-linux-server: unquote('"\\eceb"');
$ni-note-add-fill: unquote('"\\eced"');
$ni-repeat-fill: unquote('"\\ecee"');
$ni-tranx-fill: unquote('"\\ecef"');
$ni-ubuntu: unquote('"\\ecf0"');
$ni-virus: unquote('"\\ecf1"');
$ni-b-si: unquote('"\\ec98"');
$ni-brick-fill: unquote('"\\ec9a"');
$ni-brick: unquote('"\\ec9b"');
$ni-col-3s: unquote('"\\ec9c"');
$ni-col-4s: unquote('"\\ec9d"');
$ni-col-2s: unquote('"\\ec9e"');
$ni-comments: unquote('"\\ec9f"');
$ni-dot-sq: unquote('"\\eca0"');
$ni-dot: unquote('"\\eca1"');
$ni-footer: unquote('"\\eca2"');
$ni-header: unquote('"\\eca3"');
$ni-heading: unquote('"\\eca4"');
$ni-layout-alt-fill: unquote('"\\eca5"');
$ni-layout-alt: unquote('"\\eca6"');
$ni-layout-fill1: unquote('"\\eca7"');
$ni-layout1: unquote('"\\eca8"');
$ni-list-index-fill: unquote('"\\eca9"');
$ni-list-index: unquote('"\\ecaa"');
$ni-list-thumb-alt-fill: unquote('"\\ecab"');
$ni-list-thumb-alt: unquote('"\\ecac"');
$ni-list-thumb-fill: unquote('"\\ecad"');
$ni-list-thumb: unquote('"\\ecae"');
$ni-masonry-fill: unquote('"\\ecaf"');
$ni-masonry: unquote('"\\ecb0"');
$ni-menu-circled: unquote('"\\ecb1"');
$ni-menu-squared: unquote('"\\ecb2"');
$ni-notice: unquote('"\\ecb3"');
$ni-pen2: unquote('"\\ecb4"');
$ni-propert-blank: unquote('"\\ecb5"');
$ni-property-add: unquote('"\\ecb6"');
$ni-property-alt: unquote('"\\ecb7"');
$ni-property-remove: unquote('"\\ecb8"');
$ni-property: unquote('"\\ecb9"');
$ni-puzzle-fill: unquote('"\\ecba"');
$ni-puzzle: unquote('"\\ecbb"');
$ni-quote-left: unquote('"\\ecbc"');
$ni-quote-right: unquote('"\\ecbd"');
$ni-row-mix: unquote('"\\ecbe"');
$ni-row-view1: unquote('"\\ecbf"');
$ni-sidebar-r: unquote('"\\ecc0"');
$ni-text2: unquote('"\\ecc1"');
$ni-tile-thumb-fill: unquote('"\\ecc2"');
$ni-tile-thumb: unquote('"\\ecc3"');
$ni-view-col-fill: unquote('"\\ecc4"');
$ni-view-col-sq: unquote('"\\ecc5"');
$ni-view-col: unquote('"\\ecc6"');
$ni-view-col2: unquote('"\\ecc7"');
$ni-view-col3: unquote('"\\ecc8"');
$ni-view-cols-fill: unquote('"\\ecc9"');
$ni-view-cols-sq: unquote('"\\ecca"');
$ni-view-cols: unquote('"\\eccb"');
$ni-view-grid-fill: unquote('"\\eccc"');
$ni-view-grid-sq: unquote('"\\eccd"');
$ni-view-grid-wd: unquote('"\\ecce"');
$ni-view-grid: unquote('"\\eccf"');
$ni-view-grid2-wd: unquote('"\\ecd0"');
$ni-view-grid3-wd: unquote('"\\ecd1"');
$ni-view-group-fill: unquote('"\\ecd2"');
$ni-view-group-wd: unquote('"\\ecd3"');
$ni-view-list-fill: unquote('"\\ecd4"');
$ni-view-list-sq: unquote('"\\ecd5"');
$ni-view-list-wd: unquote('"\\ecd6"');
$ni-view-list: unquote('"\\ecd7"');
$ni-view-panel-fill: unquote('"\\ecd8"');
$ni-view-panel-sq: unquote('"\\ecd9"');
$ni-view-panel: unquote('"\\ecda"');
$ni-view-row-fill: unquote('"\\ecdb"');
$ni-view-row-sq: unquote('"\\ecdc"');
$ni-view-row-wd: unquote('"\\ecdd"');
$ni-view-row: unquote('"\\ecde"');
$ni-view-x1: unquote('"\\ecdf"');
$ni-view-x2: unquote('"\\ece0"');
$ni-view-x3: unquote('"\\ece1"');
$ni-view-x4: unquote('"\\ece2"');
$ni-view-x5: unquote('"\\ece3"');
$ni-view-x6: unquote('"\\ece4"');
$ni-view-x7: unquote('"\\ece5"');
$ni-dashlite: unquote('"\\eb91"');
$ni-dashlite-circle: unquote('"\\ec56"');
$ni-dashlite-alt: unquote('"\\ec57"');
$ni-master-card: unquote('"\\ec58"');
$ni-paypal: unquote('"\\ec59"');
$ni-visa-alt: unquote('"\\ec5a"');
$ni-coin-eur: unquote('"\\ec5b"');
$ni-coin-gbp: unquote('"\\ec5c"');
$ni-sign-ada-alt: unquote('"\\ec5d"');
$ni-sign-bch-alt: unquote('"\\ec5e"');
$ni-sign-bgp-alt: unquote('"\\ec5f"');
$ni-sign-bnb-alt: unquote('"\\ec60"');
$ni-sign-brl-alt: unquote('"\\ec61"');
$ni-sign-btc-alt: unquote('"\\ec62"');
$ni-sign-cc-alt: unquote('"\\ec65"');
$ni-sign-cc-alt2: unquote('"\\ec66"');
$ni-sign-chf-alt: unquote('"\\ec67"');
$ni-sign-cny-alt: unquote('"\\ec68"');
$ni-sign-czk-alt: unquote('"\\ec69"');
$ni-sign-dash-alt: unquote('"\\ec6a"');
$ni-sign-dkk-alt: unquote('"\\ec6b"');
$ni-sign-eos-alt: unquote('"\\ec6c"');
$ni-sign-eth-alt: unquote('"\\ec6d"');
$ni-sign-eur-alt2: unquote('"\\ec6e"');
$ni-sign-euro-alt: unquote('"\\ec6f"');
$ni-sign-gbp-alt2: unquote('"\\ec70"');
$ni-sign-hkd-alt: unquote('"\\ec71"');
$ni-sign-idr-alt: unquote('"\\ec72"');
$ni-sign-inr-alt: unquote('"\\ec73"');
$ni-sign-jpy-alt: unquote('"\\ec74"');
$ni-sign-kr-alt: unquote('"\\ec75"');
$ni-sign-ltc-alt: unquote('"\\ec76"');
$ni-sign-ltc: unquote('"\\ec77"');
$ni-sign-mxn-alt: unquote('"\\ec78"');
$ni-sign-mxr-alt: unquote('"\\ec79"');
$ni-sign-myr-alt: unquote('"\\ec7a"');
$ni-sign-paypal-alt: unquote('"\\ec7b"');
$ni-sign-paypal-full: unquote('"\\ec7c"');
$ni-sign-php-alt: unquote('"\\ec7d"');
$ni-sign-pln-alt: unquote('"\\ec7e"');
$ni-sign-rub-alt: unquote('"\\ec7f"');
$ni-sign-sek-alt: unquote('"\\ec80"');
$ni-sign-sgd-alt: unquote('"\\ec81"');
$ni-sign-kobo-alt: unquote('"\\ec82"');
$ni-sign-steem-alt: unquote('"\\ec83"');
$ni-sign-steller-alt: unquote('"\\ec84"');
$ni-sign-stripe-fulll: unquote('"\\ec85"');
$ni-sign-thb-alt: unquote('"\\ec86"');
$ni-sign-trx-alt: unquote('"\\ec87"');
$ni-sign-try-alt: unquote('"\\ec88"');
$ni-sign-usd-alt: unquote('"\\ec89"');
$ni-sign-usd-alt2: unquote('"\\ec8a"');
$ni-sign-usdc-alt: unquote('"\\ec8b"');
$ni-sign-usdt-alt: unquote('"\\ec8c"');
$ni-sign-visa-alt: unquote('"\\ec8d"');
$ni-sign-vnd-alt: unquote('"\\ec8e"');
$ni-sign-waves-alt: unquote('"\\ec8f"');
$ni-sign-xem-alt: unquote('"\\ec90"');
$ni-sign-xrp-new-alt: unquote('"\\ec91"');
$ni-sign-xrp-old-alt: unquote('"\\ec92"');
$ni-sign-zcash-alt: unquote('"\\ec93"');
$ni-chevron-left: unquote('"\\e9cc"');
$ni-chevron-right: unquote('"\\e9d3"');
$ni-chevron-up: unquote('"\\e9da"');
$ni-chevron-down: unquote('"\\e9c5"');
$ni-chevron-left-round: unquote('"\\e9cb"');
$ni-chevron-right-round: unquote('"\\e9d2"');
$ni-chevron-up-round: unquote('"\\e9d9"');
$ni-chevron-down-round: unquote('"\\e9c4"');
$ni-chevron-left-round-fill: unquote('"\\e9ca"');
$ni-chevron-right-round-fill: unquote('"\\e9d1"');
$ni-chevron-up-round-fill: unquote('"\\e9d8"');
$ni-chevron-down-round-fill: unquote('"\\e9c3"');
$ni-chevron-left-c: unquote('"\\e9c6"');
$ni-chevron-right-c: unquote('"\\e9cd"');
$ni-chevron-up-c: unquote('"\\e9d4"');
$ni-chevron-down-c: unquote('"\\e9bf"');
$ni-chevron-left-fill-c: unquote('"\\e9c9"');
$ni-chevron-right-fill-c: unquote('"\\e9d0"');
$ni-chevron-up-fill-c: unquote('"\\e9d7"');
$ni-chevron-down-fill-c: unquote('"\\e9c2"');
$ni-chevron-left-circle: unquote('"\\e9c8"');
$ni-chevron-right-circle: unquote('"\\e9cf"');
$ni-chevron-up-circle: unquote('"\\e9d6"');
$ni-chevron-down-circle: unquote('"\\e9c1"');
$ni-chevron-left-circle-fill: unquote('"\\e9c7"');
$ni-chevron-right-circle-fill: unquote('"\\e9ce"');
$ni-chevron-up-circle-fill: unquote('"\\e9d5"');
$ni-chevron-down-circle-fill: unquote('"\\e9c0"');
$ni-caret-left: unquote('"\\e99b"');
$ni-caret-right: unquote('"\\e99d"');
$ni-caret-up: unquote('"\\e99f"');
$ni-caret-down: unquote('"\\e999"');
$ni-caret-left-fill: unquote('"\\e99a"');
$ni-caret-right-fill: unquote('"\\e99c"');
$ni-caret-up-fill: unquote('"\\e99e"');
$ni-caret-down-fill: unquote('"\\e998"');
$ni-sort: unquote('"\\ebbf"');
$ni-sort-up: unquote('"\\ebbd"');
$ni-sort-down: unquote('"\\ebb9"');
$ni-sort-fill: unquote('"\\ebba"');
$ni-sort-up-fill: unquote('"\\ebbc"');
$ni-sort-down-fill: unquote('"\\ebb8"');
$ni-sort-v: unquote('"\\ebbe"');
$ni-swap-v: unquote('"\\ebdc"');
$ni-swap: unquote('"\\ebdd"');
$ni-arrow-left-round: unquote('"\\e937"');
$ni-arrow-right-round: unquote('"\\e942"');
$ni-arrow-up-round: unquote('"\\e94f"');
$ni-arrow-down-round: unquote('"\\e92c"');
$ni-arrow-left-round-fill: unquote('"\\e936"');
$ni-arrow-right-round-fill: unquote('"\\e941"');
$ni-arrow-up-round-fill: unquote('"\\e94e"');
$ni-arrow-down-round-fill: unquote('"\\e92b"');
$ni-arrow-left-c: unquote('"\\e932"');
$ni-arrow-right-c: unquote('"\\e93d"');
$ni-arrow-up-c: unquote('"\\e948"');
$ni-arrow-down-c: unquote('"\\e925"');
$ni-arrow-left-fill-c: unquote('"\\e935"');
$ni-arrow-right-fill-c: unquote('"\\e940"');
$ni-arrow-up-fill-c: unquote('"\\e94b"');
$ni-arrow-down-fill-c: unquote('"\\e928"');
$ni-arrow-left-circle: unquote('"\\e934"');
$ni-arrow-right-circle: unquote('"\\e93f"');
$ni-arrow-up-circle: unquote('"\\e94a"');
$ni-arrow-down-circle: unquote('"\\e927"');
$ni-arrow-left-circle-fill: unquote('"\\e933"');
$ni-arrow-up-circle-fill: unquote('"\\e949"');
$ni-arrow-down-circle-fill: unquote('"\\e926"');
$ni-arrow-right-circle-fill: unquote('"\\e93e"');
$ni-chevrons-left: unquote('"\\e9dc"');
$ni-chevrons-right: unquote('"\\e9dd"');
$ni-chevrons-up: unquote('"\\e9de"');
$ni-chevrons-down: unquote('"\\e9db"');
$ni-first: unquote('"\\ea59"');
$ni-last: unquote('"\\eab8"');
$ni-back-ios: unquote('"\\e958"');
$ni-forward-ios: unquote('"\\ea6d"');
$ni-upword-ios: unquote('"\\ec20"');
$ni-downward-ios: unquote('"\\ea23"');
$ni-back-alt: unquote('"\\e955"');
$ni-forward-alt: unquote('"\\ea69"');
$ni-upword-alt: unquote('"\\ec1f"');
$ni-downward-alt: unquote('"\\ea22"');
$ni-back-alt-fill: unquote('"\\e954"');
$ni-forward-alt-fill: unquote('"\\ea68"');
$ni-upword-alt-fill: unquote('"\\ec1e"');
$ni-downward-alt-fill: unquote('"\\ea21"');
$ni-arrow-long-left: unquote('"\\e93a"');
$ni-arrow-long-right: unquote('"\\e93b"');
$ni-arrow-long-up: unquote('"\\e93c"');
$ni-arrow-long-down: unquote('"\\e939"');
$ni-arrow-left: unquote('"\\e938"');
$ni-arrow-right: unquote('"\\e943"');
$ni-arrow-up: unquote('"\\e950"');
$ni-arrow-down: unquote('"\\e92d"');
$ni-arrow-up-left: unquote('"\\e94c"');
$ni-arrow-up-right: unquote('"\\e94d"');
$ni-arrow-down-left: unquote('"\\e929"');
$ni-arrow-down-right: unquote('"\\e92a"');
$ni-arrow-to-left: unquote('"\\e945"');
$ni-arrow-to-right: unquote('"\\e946"');
$ni-arrow-to-up: unquote('"\\e947"');
$ni-arrow-to-down: unquote('"\\e944"');
$ni-arrow-from-left: unquote('"\\e92f"');
$ni-arrow-from-right: unquote('"\\e930"');
$ni-arrow-from-up: unquote('"\\e931"');
$ni-arrow-from-down: unquote('"\\e92e"');
$ni-curve-down-left: unquote('"\\ea0b"');
$ni-curve-up-right: unquote('"\\ea12"');
$ni-curve-up-left: unquote('"\\ea11"');
$ni-curve-down-right: unquote('"\\ea0c"');
$ni-curve-left-up: unquote('"\\ea0e"');
$ni-curve-right-up: unquote('"\\ea10"');
$ni-curve-left-down: unquote('"\\ea0d"');
$ni-curve-right-down: unquote('"\\ea0f"');
$ni-back-arrow: unquote('"\\e957"');
$ni-forward-arrow: unquote('"\\ea6b"');
$ni-back-arrow-fill: unquote('"\\e956"');
$ni-forward-arrow-fill: unquote('"\\ea6a"');
$ni-navigate: unquote('"\\eb0f"');
$ni-navigate-up: unquote('"\\eb0e"');
$ni-navigate-fill: unquote('"\\eb0c"');
$ni-navigate-up-fill: unquote('"\\eb0d"');
$ni-send: unquote('"\\eb6f"');
$ni-send-alt: unquote('"\\eb6e"');
$ni-unfold-less: unquote('"\\ec15"');
$ni-unfold-more: unquote('"\\ec16"');
$ni-exchange-v: unquote('"\\ea32"');
$ni-exchange: unquote('"\\ea33"');
$ni-expand: unquote('"\\ea34"');
$ni-shrink: unquote('"\\eb83"');
$ni-focus: unquote('"\\ea5e"');
$ni-maximize: unquote('"\\eadf"');
$ni-minimize: unquote('"\\eaf2"');
$ni-maximize-alt: unquote('"\\eade"');
$ni-minimize-alt: unquote('"\\eaf1"');
$ni-shuffle: unquote('"\\eb84"');
$ni-cross-sm: unquote('"\\ea05"');
$ni-cross: unquote('"\\ea06"');
$ni-cross-round: unquote('"\\ea04"');
$ni-cross-circle: unquote('"\\ea01"');
$ni-cross-c: unquote('"\\e9ff"');
$ni-cross-round-fill: unquote('"\\ea03"');
$ni-cross-circle-fill: unquote('"\\ea00"');
$ni-cross-fill-c: unquote('"\\ea02"');
$ni-na: unquote('"\\eb0b"');
$ni-check: unquote('"\\e9be"');
$ni-check-thick: unquote('"\\e9bd"');
$ni-done: unquote('"\\ea1c"');
$ni-check-round: unquote('"\\e9bc"');
$ni-check-circle: unquote('"\\e9b8"');
$ni-check-c: unquote('"\\e9b5"');
$ni-check-round-fill: unquote('"\\e9bb"');
$ni-check-circle-fill: unquote('"\\e9b7"');
$ni-check-fill-c: unquote('"\\e9b9"');
$ni-check-circle-cut: unquote('"\\e9b6"');
$ni-check-round-cut: unquote('"\\e9ba"');
$ni-bullet: unquote('"\\e981"');
$ni-circle: unquote('"\\e9e0"');
$ni-square: unquote('"\\ebca"');
$ni-square-c: unquote('"\\ebc7"');
$ni-bullet-fill: unquote('"\\e980"');
$ni-circle-fill: unquote('"\\e9df"');
$ni-square-fill: unquote('"\\ebc9"');
$ni-square-fill-c: unquote('"\\ebc8"');
$ni-plus-sm: unquote('"\\eb43"');
$ni-minus-sm: unquote('"\\eaf9"');
$ni-plus: unquote('"\\eb44"');
$ni-minus: unquote('"\\eafa"');
$ni-plus-round: unquote('"\\eb42"');
$ni-minus-round: unquote('"\\eaf8"');
$ni-plus-circle: unquote('"\\eb3d"');
$ni-minus-circle: unquote('"\\eaf5"');
$ni-plus-c: unquote('"\\eb3b"');
$ni-minus-c: unquote('"\\eaf3"');
$ni-plus-round-fill: unquote('"\\eb41"');
$ni-plus-circle-fill: unquote('"\\eb3c"');
$ni-minus-round-fill: unquote('"\\eaf7"');
$ni-minus-circle-fill: unquote('"\\eaf4"');
$ni-plus-fill-c: unquote('"\\eb3e"');
$ni-minus-fill-c: unquote('"\\eaf6"');
$ni-plus-medi: unquote('"\\eb40"');
$ni-plus-medi-fill: unquote('"\\eb3f"');
$ni-equal-sm: unquote('"\\ea30"');
$ni-equal: unquote('"\\ea31"');
$ni-calc: unquote('"\\e982"');
$ni-search: unquote('"\\eb6c"');
$ni-zoom-out: unquote('"\\ec55"');
$ni-zoom-in: unquote('"\\ec54"');
$ni-play: unquote('"\\eb3a"');
$ni-play-fill: unquote('"\\eb39"');
$ni-play-circle: unquote('"\\eb38"');
$ni-play-circle-fill: unquote('"\\eb37"');
$ni-pause: unquote('"\\eb26"');
$ni-pause-fill: unquote('"\\eb25"');
$ni-pause-circle: unquote('"\\eb24"');
$ni-pause-circle-fill: unquote('"\\eb23"');
$ni-stop: unquote('"\\ebd6"');
$ni-stop-fill: unquote('"\\ebd5"');
$ni-stop-circle: unquote('"\\ebd4"');
$ni-stop-circle-fill: unquote('"\\ebd3"');
$ni-rewind: unquote('"\\eb62"');
$ni-forward: unquote('"\\ea6e"');
$ni-rewind-fill: unquote('"\\eb61"');
$ni-forward-fill: unquote('"\\ea6c"');
$ni-step-back: unquote('"\\ebd1"');
$ni-step-forward: unquote('"\\ebd2"');
$ni-vol-off: unquote('"\\ec40"');
$ni-vol-no: unquote('"\\ec3f"');
$ni-vol-half: unquote('"\\ec3e"');
$ni-vol: unquote('"\\ec41"');
$ni-mic: unquote('"\\eaef"');
$ni-mic-off: unquote('"\\eaee"');
$ni-video: unquote('"\\ec39"');
$ni-video-off: unquote('"\\ec38"');
$ni-video-fill: unquote('"\\ec37"');
$ni-loader: unquote('"\\ead2"');
$ni-power: unquote('"\\eb47"');
$ni-signout: unquote('"\\ebb2"');
$ni-signin: unquote('"\\ebb1"');
$ni-upload: unquote('"\\ec1d"');
$ni-download: unquote('"\\ea20"');
$ni-alert-circle: unquote('"\\e90d"');
$ni-alert: unquote('"\\e910"');
$ni-caution: unquote('"\\e9a3"');
$ni-report: unquote('"\\eb5e"');
$ni-alert-c: unquote('"\\e90b"');
$ni-alert-circle-fill: unquote('"\\e90c"');
$ni-alert-fill: unquote('"\\e90f"');
$ni-caution-fill: unquote('"\\e9a2"');
$ni-report-fill: unquote('"\\eb5c"');
$ni-alert-fill-c: unquote('"\\e90e"');
$ni-info-i: unquote('"\\eaa8"');
$ni-info: unquote('"\\eaa9"');
$ni-info-fill: unquote('"\\eaa7"');
$ni-help: unquote('"\\ea98"');
$ni-help-fill: unquote('"\\ea97"');
$ni-archived: unquote('"\\e924"');
$ni-archive: unquote('"\\e922"');
$ni-unarchive: unquote('"\\ec12"');
$ni-archived-fill: unquote('"\\e923"');
$ni-archive-fill: unquote('"\\e921"');
$ni-unarchive-fill: unquote('"\\ec11"');
$ni-bag: unquote('"\\e95a"');
$ni-bag-fill: unquote('"\\e959"');
$ni-bell: unquote('"\\e965"');
$ni-bell-off: unquote('"\\e964"');
$ni-bell-fill: unquote('"\\e962"');
$ni-bell-off-fill: unquote('"\\e963"');
$ni-wifi: unquote('"\\ec4b"');
$ni-wifi-off: unquote('"\\ec4a"');
$ni-live: unquote('"\\ead1"');
$ni-signal: unquote('"\\ebb0"');
$ni-bluetooth: unquote('"\\e96d"');
$ni-blank-alt: unquote('"\\e967"');
$ni-blank: unquote('"\\e968"');
$ni-blankf-fill: unquote('"\\e969"');
$ni-block-over: unquote('"\\e96a"');
$ni-book-read: unquote('"\\e970"');
$ni-book: unquote('"\\e971"');
$ni-book-fill: unquote('"\\e96f"');
$ni-bulb-fill: unquote('"\\e97e"');
$ni-bulb: unquote('"\\e97f"');
$ni-calendar-alt-fill: unquote('"\\e983"');
$ni-calendar-alt: unquote('"\\e984"');
$ni-calendar-booking-fill: unquote('"\\e985"');
$ni-calendar-booking: unquote('"\\e986"');
$ni-calendar-check-fill: unquote('"\\e987"');
$ni-calendar-check: unquote('"\\e988"');
$ni-calendar-fill: unquote('"\\e989"');
$ni-calendar: unquote('"\\e98a"');
$ni-calender-date-fill: unquote('"\\e98b"');
$ni-calender-date: unquote('"\\e98c"');
$ni-call: unquote('"\\e990"');
$ni-call-alt: unquote('"\\e98e"');
$ni-call-alt-fill: unquote('"\\e98d"');
$ni-call-fill: unquote('"\\e98f"');
$ni-camera-fill: unquote('"\\e991"');
$ni-camera: unquote('"\\e992"');
$ni-capsule: unquote('"\\e994"');
$ni-capsule-fill: unquote('"\\e993"');
$ni-cards: unquote('"\\e997"');
$ni-cards-fill: unquote('"\\e996"');
$ni-cart: unquote('"\\e9a1"');
$ni-cart-fill: unquote('"\\e9a0"');
$ni-cc: unquote('"\\e9ae"');
$ni-cc-alt: unquote('"\\e9a5"');
$ni-cc-alt2: unquote('"\\e9a7"');
$ni-cc-secure: unquote('"\\e9ac"');
$ni-cc-new: unquote('"\\e9a9"');
$ni-cc-off: unquote('"\\e9aa"');
$ni-cc-fill: unquote('"\\e9a8"');
$ni-cc-alt-fill: unquote('"\\e9a4"');
$ni-cc-alt2-fill: unquote('"\\e9a6"');
$ni-cc-secure-fill: unquote('"\\e9ab"');
$ni-msg-circle: unquote('"\\eb07"');
$ni-chat-circle: unquote('"\\e9b2"');
$ni-msg: unquote('"\\eb09"');
$ni-chat: unquote('"\\e9b4"');
$ni-question-alt: unquote('"\\eb4b"');
$ni-question: unquote('"\\eb4c"');
$ni-msg-circle-fill: unquote('"\\eb06"');
$ni-chat-circle-fill: unquote('"\\e9b1"');
$ni-msg-fill: unquote('"\\eb08"');
$ni-chat-fill: unquote('"\\e9b3"');
$ni-clip-h: unquote('"\\e9e1"');
$ni-clip-v: unquote('"\\e9e2"');
$ni-clip: unquote('"\\e9e3"');
$ni-link-alt: unquote('"\\eac5"');
$ni-unlink: unquote('"\\ec18"');
$ni-unlink-alt: unquote('"\\ec17"');
$ni-link-h: unquote('"\\eac7"');
$ni-link-v: unquote('"\\eac8"');
$ni-link: unquote('"\\eac9"');
$ni-clipboard: unquote('"\\e9e7"');
$ni-clipboad-check: unquote('"\\e9e5"');
$ni-clipboard-fill: unquote('"\\e9e6"');
$ni-clipboad-check-fill: unquote('"\\e9e4"');
$ni-clock: unquote('"\\e9e9"');
$ni-clock-fill: unquote('"\\e9e8"');
$ni-cloud: unquote('"\\e9eb"');
$ni-upload-cloud: unquote('"\\ec1c"');
$ni-download-cloud: unquote('"\\ea1f"');
$ni-cloud-fill: unquote('"\\e9ea"');
$ni-contact: unquote('"\\e9f9"');
$ni-contact-fill: unquote('"\\e9f8"');
$ni-coffee: unquote('"\\e9f0"');
$ni-coffee-fill: unquote('"\\e9ef"');
$ni-box-view: unquote('"\\e976"');
$ni-col-view: unquote('"\\e9f5"');
$ni-sidebar: unquote('"\\eb86"');
$ni-layout: unquote('"\\eabe"');
$ni-table-view: unquote('"\\ebdf"');
$ni-layout2: unquote('"\\eabf"');
$ni-row-view: unquote('"\\eb63"');
$ni-dot-box: unquote('"\\ea1e"');
$ni-layout-fill: unquote('"\\eabd"');
$ni-box-view-fill: unquote('"\\e975"');
$ni-sidebar-fill: unquote('"\\eb85"');
$ni-table-view-fill: unquote('"\\ebde"');
$ni-dot-box-fill: unquote('"\\ea1d"');
$ni-template: unquote('"\\ebee"');
$ni-browser: unquote('"\\e97a"');
$ni-toolbar: unquote('"\\ec02"');
$ni-browser-fill: unquote('"\\e979"');
$ni-toolbar-fill: unquote('"\\ec01"');
$ni-template-fill: unquote('"\\ebed"');
$ni-box: unquote('"\\e977"');
$ni-package: unquote('"\\eb20"');
$ni-layer: unquote('"\\eaba"');
$ni-layers: unquote('"\\eabc"');
$ni-panel: unquote('"\\eb22"');
$ni-server: unquote('"\\eb71"');
$ni-layer-fill: unquote('"\\eab9"');
$ni-layers-fill: unquote('"\\eabb"');
$ni-package-fill: unquote('"\\eb1f"');
$ni-panel-fill: unquote('"\\eb21"');
$ni-server-fill: unquote('"\\eb70"');
$ni-color-palette: unquote('"\\e9f7"');
$ni-color-palette-fill: unquote('"\\e9f6"');
$ni-copy: unquote('"\\e9fb"');
$ni-copy-fill: unquote('"\\e9fa"');
$ni-crop-alt: unquote('"\\e9fd"');
$ni-crop: unquote('"\\e9fe"');
$ni-target: unquote('"\\ebe7"');
$ni-crosshair: unquote('"\\ea08"');
$ni-crosshair-fill: unquote('"\\ea07"');
$ni-db-fill: unquote('"\\ea15"');
$ni-db: unquote('"\\ea16"');
$ni-hard-drive: unquote('"\\ea90"');
$ni-cpu: unquote('"\\e9fc"');
$ni-disk: unquote('"\\ea1b"');
$ni-pen: unquote('"\\eb2b"');
$ni-edit-alt: unquote('"\\ea29"');
$ni-pen-fill: unquote('"\\eb2a"');
$ni-edit-alt-fill: unquote('"\\ea28"');
$ni-pen-alt-fill: unquote('"\\eb29"');
$ni-edit-fill: unquote('"\\ea2a"');
$ni-edit: unquote('"\\ea2b"');
$ni-external-alt: unquote('"\\ea35"');
$ni-external: unquote('"\\ea36"');
$ni-eye-alt: unquote('"\\ea38"');
$ni-eye-alt-fill: unquote('"\\ea37"');
$ni-eye: unquote('"\\ea3c"');
$ni-eye-fill: unquote('"\\ea39"');
$ni-eye-off: unquote('"\\ea3b"');
$ni-eye-off-fill: unquote('"\\ea3a"');
$ni-file: unquote('"\\ea53"');
$ni-file-minus: unquote('"\\ea49"');
$ni-file-plus: unquote('"\\ea4c"');
$ni-file-remove: unquote('"\\ea4e"');
$ni-file-check: unquote('"\\ea41"');
$ni-file-code: unquote('"\\ea42"');
$ni-file-docs: unquote('"\\ea44"');
$ni-file-img: unquote('"\\ea47"');
$ni-file-doc: unquote('"\\ea43"');
$ni-file-pdf: unquote('"\\ea4a"');
$ni-file-xls: unquote('"\\ea51"');
$ni-file-zip: unquote('"\\ea52"');
$ni-file-download: unquote('"\\ea45"');
$ni-file-text: unquote('"\\ea50"');
$ni-files: unquote('"\\ea55"');
$ni-file-fill: unquote('"\\ea46"');
$ni-file-minus-fill: unquote('"\\ea48"');
$ni-file-plus-fill: unquote('"\\ea4b"');
$ni-file-remove-fill: unquote('"\\ea4d"');
$ni-file-check-fill: unquote('"\\ea40"');
$ni-file-text-fill: unquote('"\\ea4f"');
$ni-files-fill: unquote('"\\ea54"');
$ni-folder: unquote('"\\ea65"');
$ni-folder-minus: unquote('"\\ea62"');
$ni-folder-plus: unquote('"\\ea63"');
$ni-folder-remove: unquote('"\\ea64"');
$ni-folder-check: unquote('"\\ea5f"');
$ni-folder-list: unquote('"\\ea61"');
$ni-folders: unquote('"\\ea67"');
$ni-folder-fill: unquote('"\\ea60"');
$ni-folders-fill: unquote('"\\ea66"');
$ni-filter-alt: unquote('"\\ea56"');
$ni-sort-line: unquote('"\\ebbb"');
$ni-filter-fill: unquote('"\\ea57"');
$ni-filter: unquote('"\\ea58"');
$ni-flag: unquote('"\\ea5b"');
$ni-flag-fill: unquote('"\\ea5a"');
$ni-notify: unquote('"\\eb16"');
$ni-dashboard: unquote('"\\ea14"');
$ni-dashboard-fill: unquote('"\\ea13"');
$ni-grid-sq: unquote('"\\ea89"');
$ni-grid: unquote('"\\ea8a"');
$ni-grid-c: unquote('"\\ea83"');
$ni-grid-alt: unquote('"\\ea7e"');
$ni-grid-plus: unquote('"\\ea88"');
$ni-grid-add-c: unquote('"\\ea7b"');
$ni-grid-fill: unquote('"\\ea85"');
$ni-grid-fill-c: unquote('"\\ea84"');
$ni-grid-alt-fill: unquote('"\\ea7d"');
$ni-grid-plus-fill: unquote('"\\ea87"');
$ni-grid-add-fill-c: unquote('"\\ea7c"');
$ni-grid-box-alt-fill: unquote('"\\ea7f"');
$ni-grid-box-alt: unquote('"\\ea80"');
$ni-grid-box: unquote('"\\ea82"');
$ni-grid-box-fill: unquote('"\\ea81"');
$ni-grid-line: unquote('"\\ea86"');
$ni-menu-alt-left: unquote('"\\eae6"');
$ni-menu-alt-r: unquote('"\\eae7"');
$ni-menu-alt: unquote('"\\eae8"');
$ni-menu-center: unquote('"\\eae9"');
$ni-menu-left: unquote('"\\eaea"');
$ni-menu-right: unquote('"\\eaeb"');
$ni-menu: unquote('"\\eaec"');
$ni-trend-up: unquote('"\\ec0a"');
$ni-trend-down: unquote('"\\ec09"');
$ni-line-chart-down: unquote('"\\eac2"');
$ni-line-chart-up: unquote('"\\eac3"');
$ni-line-chart: unquote('"\\eac4"');
$ni-bar-chart: unquote('"\\e95e"');
$ni-bar-chart-alt: unquote('"\\e95c"');
$ni-chart-up: unquote('"\\e9b0"');
$ni-chart-down: unquote('"\\e9af"');
$ni-growth: unquote('"\\ea8c"');
$ni-growth-fill: unquote('"\\ea8b"');
$ni-bar-chart-fill: unquote('"\\e95d"');
$ni-bar-c: unquote('"\\e95b"');
$ni-bar-fill-c: unquote('"\\e95f"');
$ni-pie: unquote('"\\eb30"');
$ni-pie-alt: unquote('"\\eb2e"');
$ni-pie-fill: unquote('"\\eb2f"');
$ni-activity: unquote('"\\e906"');
$ni-activity-alt: unquote('"\\e903"');
$ni-activity-round: unquote('"\\e905"');
$ni-activity-round-fill: unquote('"\\e904"');
$ni-meter: unquote('"\\eaed"');
$ni-speed: unquote('"\\ebc5"');
$ni-happy: unquote('"\\ea8e"');
$ni-sad: unquote('"\\eb66"');
$ni-meh: unquote('"\\eae5"');
$ni-happyf-fill: unquote('"\\ea8f"');
$ni-sad-fill: unquote('"\\eb65"');
$ni-meh-fill: unquote('"\\eae4"');
$ni-home: unquote('"\\ea9c"');
$ni-home-alt: unquote('"\\ea9a"');
$ni-home-fill: unquote('"\\ea9b"');
$ni-img: unquote('"\\eaa0"');
$ni-img-fill: unquote('"\\ea9f"');
$ni-inbox: unquote('"\\eaa6"');
$ni-inbox-in: unquote('"\\eaa3"');
$ni-inbox-out: unquote('"\\eaa5"');
$ni-inbox-fill: unquote('"\\eaa1"');
$ni-inbox-in-fill: unquote('"\\eaa2"');
$ni-inbox-out-fill: unquote('"\\eaa4"');
$ni-link-group: unquote('"\\eac6"');
$ni-lock: unquote('"\\ead7"');
$ni-lock-alt: unquote('"\\ead5"');
$ni-lock-fill: unquote('"\\ead6"');
$ni-lock-alt-fill: unquote('"\\ead4"');
$ni-unlock: unquote('"\\ec1a"');
$ni-unlock-fill: unquote('"\\ec19"');
$ni-mail: unquote('"\\eada"');
$ni-emails: unquote('"\\ea2e"');
$ni-mail-fill: unquote('"\\ead9"');
$ni-emails-fill: unquote('"\\ea2d"');
$ni-map-pin: unquote('"\\eadc"');
$ni-location: unquote('"\\ead3"');
$ni-map: unquote('"\\eadd"');
$ni-map-pin-fill: unquote('"\\eadb"');
$ni-list: unquote('"\\ead0"');
$ni-list-ol: unquote('"\\eace"');
$ni-align-center: unquote('"\\e911"');
$ni-align-justify: unquote('"\\e912"');
$ni-align-left: unquote('"\\e913"');
$ni-align-right: unquote('"\\e914"');
$ni-list-check: unquote('"\\eacc"');
$ni-list-round: unquote('"\\eacf"');
$ni-card-view: unquote('"\\e995"');
$ni-list-fill: unquote('"\\eacd"');
$ni-save: unquote('"\\eb68"');
$ni-save-fill: unquote('"\\eb67"');
$ni-move: unquote('"\\eb04"');
$ni-scissor: unquote('"\\eb6b"');
$ni-text: unquote('"\\ebf2"');
$ni-text-a: unquote('"\\ebf0"');
$ni-bold: unquote('"\\e96e"');
$ni-italic: unquote('"\\eaad"');
$ni-underline: unquote('"\\ec13"');
$ni-percent: unquote('"\\eb2c"');
$ni-at: unquote('"\\e952"');
$ni-hash: unquote('"\\ea91"');
$ni-code: unquote('"\\e9ee"');
$ni-code-download: unquote('"\\e9ed"');
$ni-terminal: unquote('"\\ebef"');
$ni-cmd: unquote('"\\e9ec"');
$ni-sun: unquote('"\\ebd9"');
$ni-sun-fill: unquote('"\\ebd8"');
$ni-moon-fill: unquote('"\\eafe"');
$ni-moon: unquote('"\\eaff"');
$ni-light: unquote('"\\eac1"');
$ni-light-fill: unquote('"\\eac0"');
$ni-more-v: unquote('"\\eb03"');
$ni-more-h: unquote('"\\eb01"');
$ni-more-h-alt: unquote('"\\eb00"');
$ni-more-v-alt: unquote('"\\eb02"');
$ni-music: unquote('"\\eb0a"');
$ni-movie: unquote('"\\eb05"');
$ni-offer: unquote('"\\eb18"');
$ni-offer-fill: unquote('"\\eb17"');
$ni-opt-alt: unquote('"\\eb1a"');
$ni-opt: unquote('"\\eb1e"');
$ni-opt-dot-alt: unquote('"\\eb1b"');
$ni-opt-dot: unquote('"\\eb1d"');
$ni-opt-dot-fill: unquote('"\\eb1c"');
$ni-opt-alt-fill: unquote('"\\eb19"');
$ni-user-alt: unquote('"\\ec24"');
$ni-user-alt-fill: unquote('"\\ec23"');
$ni-user: unquote('"\\ec33"');
$ni-users: unquote('"\\ec35"');
$ni-user-add: unquote('"\\ec22"');
$ni-user-remove: unquote('"\\ec31"');
$ni-user-check: unquote('"\\ec27"');
$ni-user-cross: unquote('"\\ec2b"');
$ni-account-setting: unquote('"\\e902"');
$ni-account-setting-alt: unquote('"\\e900"');
$ni-user-list: unquote('"\\ec2f"');
$ni-user-fill: unquote('"\\ec2d"');
$ni-users-fill: unquote('"\\ec34"');
$ni-user-add-fill: unquote('"\\ec21"');
$ni-user-remove-fill: unquote('"\\ec30"');
$ni-user-check-fill: unquote('"\\ec26"');
$ni-user-cross-fill: unquote('"\\ec2a"');
$ni-account-setting-fill: unquote('"\\e901"');
$ni-user-list-fill: unquote('"\\ec2e"');
$ni-user-circle: unquote('"\\ec28"');
$ni-user-circle-fill: unquote('"\\ec29"');
$ni-user-c: unquote('"\\ec25"');
$ni-user-fill-c: unquote('"\\ec2c"');
$ni-user-round: unquote('"\\ec32"');
$ni-printer: unquote('"\\eb31"');
$ni-printer-fill: unquote('"\\eb48"');
$ni-laptop: unquote('"\\eab6"');
$ni-monitor: unquote('"\\eafd"');
$ni-tablet: unquote('"\\ebe0"');
$ni-mobile: unquote('"\\eafb"');
$ni-undo: unquote('"\\ec14"');
$ni-redo: unquote('"\\eb51"');
$ni-reload-alt: unquote('"\\eb54"');
$ni-reload: unquote('"\\eb55"');
$ni-regen-alt: unquote('"\\eb52"');
$ni-regen: unquote('"\\eb53"');
$ni-invest: unquote('"\\eaac"');
$ni-history: unquote('"\\ea99"');
$ni-update: unquote('"\\ec1b"');
$ni-repeat: unquote('"\\eb57"');
$ni-repeat-v: unquote('"\\eb56"');
$ni-tranx: unquote('"\\ec03"');
$ni-reply-all: unquote('"\\eb59"');
$ni-reply: unquote('"\\eb5b"');
$ni-reply-fill: unquote('"\\eb5a"');
$ni-reply-all-fill: unquote('"\\eb58"');
$ni-notes: unquote('"\\eb15"');
$ni-note-add: unquote('"\\eb13"');
$ni-notes-alt: unquote('"\\eb14"');
$ni-article: unquote('"\\e951"');
$ni-text-rich: unquote('"\\ebf1"');
$ni-todo: unquote('"\\ebfe"');
$ni-report-profit: unquote('"\\eb5d"');
$ni-reports-alt: unquote('"\\eb5f"');
$ni-reports: unquote('"\\eb60"');
$ni-task: unquote('"\\ebea"');
$ni-note-add-c: unquote('"\\eb11"');
$ni-task-c: unquote('"\\ebe8"');
$ni-todo-fill: unquote('"\\ebfd"');
$ni-note-add-fill-c: unquote('"\\eb12"');
$ni-task-fill-c: unquote('"\\ebe9"');
$ni-scan-fill: unquote('"\\eb69"');
$ni-scan: unquote('"\\eb6a"');
$ni-qr: unquote('"\\eb4a"');
$ni-money: unquote('"\\eafc"');
$ni-coins: unquote('"\\e9f4"');
$ni-coin: unquote('"\\e9f3"');
$ni-coin-alt: unquote('"\\e9f2"');
$ni-coin-alt-fill: unquote('"\\e9f1"');
$ni-setting-alt-fill: unquote('"\\eb72"');
$ni-setting-alt: unquote('"\\eb73"');
$ni-setting-fill: unquote('"\\eb74"');
$ni-setting: unquote('"\\eb75"');
$ni-share-alt: unquote('"\\eb76"');
$ni-share-fill: unquote('"\\eb77"');
$ni-share: unquote('"\\eb78"');
$ni-network: unquote('"\\eb10"');
$ni-rss: unquote('"\\eb64"');
$ni-shield: unquote('"\\eb82"');
$ni-shield-star: unquote('"\\eb81"');
$ni-shield-check: unquote('"\\eb7c"');
$ni-shield-alert: unquote('"\\eb7a"');
$ni-shield-off: unquote('"\\eb7f"');
$ni-security: unquote('"\\eb6d"');
$ni-policy: unquote('"\\eb46"');
$ni-shield-alert-fill: unquote('"\\eb79"');
$ni-shield-check-fill: unquote('"\\eb7b"');
$ni-shield-fill: unquote('"\\eb7d"');
$ni-shield-half: unquote('"\\eb7e"');
$ni-shield-star-fill: unquote('"\\eb80"');
$ni-policy-fill: unquote('"\\eb45"');
$ni-spark: unquote('"\\ebc4"');
$ni-spark-off: unquote('"\\ebc3"');
$ni-spark-fill: unquote('"\\ebc1"');
$ni-spark-off-fill: unquote('"\\ebc2"');
$ni-wallet: unquote('"\\ec47"');
$ni-wallet-alt: unquote('"\\ec42"');
$ni-wallet-in: unquote('"\\ec44"');
$ni-wallet-out: unquote('"\\ec45"');
$ni-wallet-saving: unquote('"\\ec46"');
$ni-wallet-fill: unquote('"\\ec43"');
$ni-star: unquote('"\\ebd0"');
$ni-star-half: unquote('"\\ebce"');
$ni-star-half-fill: unquote('"\\ebcd"');
$ni-star-fill: unquote('"\\ebcc"');
$ni-star-round: unquote('"\\ebcf"');
$ni-heart: unquote('"\\ea95"');
$ni-heart-fill: unquote('"\\ea94"');
$ni-swap-alt-fill: unquote('"\\ebda"');
$ni-swap-alt: unquote('"\\ebdb"');
$ni-thumbs-down: unquote('"\\ebf3"');
$ni-thumbs-up: unquote('"\\ebf4"');
$ni-tag: unquote('"\\ebe4"');
$ni-tag-alt: unquote('"\\ebe2"');
$ni-tags: unquote('"\\ebe6"');
$ni-tag-fill: unquote('"\\ebe3"');
$ni-tag-alt-fill: unquote('"\\ebe1"');
$ni-tags-fill: unquote('"\\ebe5"');
$ni-bookmark: unquote('"\\e973"');
$ni-bookmark-fill: unquote('"\\e972"');
$ni-label: unquote('"\\eab5"');
$ni-label-fill: unquote('"\\eab4"');
$ni-piority: unquote('"\\eb36"');
$ni-piority-fill: unquote('"\\eb35"');
$ni-label-alt: unquote('"\\eab3"');
$ni-label-alt-fill: unquote('"\\eab2"');
$ni-ticket-alt: unquote('"\\ebf6"');
$ni-ticket: unquote('"\\ebfc"');
$ni-ticket-minus: unquote('"\\ebf9"');
$ni-ticket-plus: unquote('"\\ebfb"');
$ni-ticket-alt-fill: unquote('"\\ebf5"');
$ni-ticket-fill: unquote('"\\ebf7"');
$ni-ticket-minus-fill: unquote('"\\ebf8"');
$ni-ticket-plus-fill: unquote('"\\ebfa"');
$ni-toggle-off: unquote('"\\ebff"');
$ni-toggle-on: unquote('"\\ec00"');
$ni-trash-alt: unquote('"\\ec04"');
$ni-trash-empty: unquote('"\\ec06"');
$ni-trash: unquote('"\\ec08"');
$ni-trash-fill: unquote('"\\ec07"');
$ni-trash-empty-fill: unquote('"\\ec05"');
$ni-delete-fill: unquote('"\\ea17"');
$ni-delete: unquote('"\\ea18"');
$ni-alarm-alt: unquote('"\\e909"');
$ni-alarm: unquote('"\\e90a"');
$ni-bugs: unquote('"\\e97b"');
$ni-building: unquote('"\\e97d"');
$ni-building-fill: unquote('"\\e97c"');
$ni-headphone: unquote('"\\ea93"');
$ni-headphone-fill: unquote('"\\ea92"');
$ni-aperture: unquote('"\\e91b"');
$ni-help-alt: unquote('"\\ea96"');
$ni-award: unquote('"\\e953"');
$ni-briefcase: unquote('"\\e978"');
$ni-gift: unquote('"\\ea6f"');
$ni-globe: unquote('"\\ea74"');
$ni-umbrela: unquote('"\\ec10"');
$ni-truck: unquote('"\\ec0b"');
$ni-sign-usd: unquote('"\\eba7"');
$ni-sign-dollar: unquote('"\\eba7"');
$ni-sign-mxn: unquote('"\\eb99"');
$ni-sign-sgd: unquote('"\\eba0"');
$ni-sign-euro: unquote('"\\eb94"');
$ni-sign-eur: unquote('"\\eb94"');
$ni-sign-gbp: unquote('"\\eb89"');
$ni-sign-pound: unquote('"\\eb89"');
$ni-sign-thb: unquote('"\\eba4"');
$ni-sign-inr: unquote('"\\eb97"');
$ni-sign-jpy: unquote('"\\eb98"');
$ni-sign-yen: unquote('"\\eb98"');
$ni-sign-cny: unquote('"\\eb8e"');
$ni-sign-kobo: unquote('"\\eba1"');
$ni-sign-chf: unquote('"\\eb8d"');
$ni-sign-vnd: unquote('"\\ebaa"');
$ni-sign-php: unquote('"\\eb9c"');
$ni-sign-brl: unquote('"\\eb8b"');
$ni-sign-idr: unquote('"\\eb96"');
$ni-sign-czk: unquote('"\\eb8f"');
$ni-sign-hkd: unquote('"\\eb95"');
$ni-sign-kr: unquote('"\\eb9f"');
$ni-sign-dkk: unquote('"\\eb9f"');
$ni-sign-nok: unquote('"\\eb9f"');
$ni-sign-sek: unquote('"\\eb9f"');
$ni-sign-rub: unquote('"\\eb9e"');
$ni-sign-myr: unquote('"\\eb9b"');
$ni-sign-pln: unquote('"\\eb9d"');
$ni-sign-try: unquote('"\\eba6"');
$ni-sign-waves: unquote('"\\ebab"');
$ni-waves: unquote('"\\ebab"');
$ni-sign-trx: unquote('"\\eba5"');
$ni-tron: unquote('"\\eba5"');
$ni-sign-xem: unquote('"\\ebac"');
$ni-nem: unquote('"\\ebac"');
$ni-sign-mxr: unquote('"\\eb9a"');
$ni-monero: unquote('"\\eb9a"');
$ni-sign-usdc: unquote('"\\eba8"');
$ni-sign-steller: unquote('"\\eba3"');
$ni-sign-steem: unquote('"\\eba2"');
$ni-sign-usdt: unquote('"\\eba9"');
$ni-tether: unquote('"\\eba9"');
$ni-sign-btc: unquote('"\\eb8c"');
$ni-bitcoin: unquote('"\\eb8c"');
$ni-sign-bch: unquote('"\\eb88"');
$ni-bitcoin-cash: unquote('"\\eb88"');
$ni-sign-bnb: unquote('"\\eb8a"');
$ni-binance: unquote('"\\eb8a"');
$ni-sign-ada: unquote('"\\eb87"');
$ni-sign-zcash: unquote('"\\ebaf"');
$ni-sign-eth: unquote('"\\eb93"');
$ni-ethereum: unquote('"\\eb93"');
$ni-sign-dash: unquote('"\\eb90"');
$ni-dash: unquote('"\\eb90"');
$ni-sign-xrp-old: unquote('"\\ebae"');
$ni-ripple-old: unquote('"\\ebae"');
$ni-sign-eos: unquote('"\\eb92"');
$ni-eos: unquote('"\\eb92"');
$ni-sign-xrp: unquote('"\\ebad"');
$ni-ripple: unquote('"\\ebad"');
$ni-american-express: unquote('"\\e918"');
$ni-jcb: unquote('"\\eaae"');
$ni-cc-jcb: unquote('"\\eaae"');
$ni-mc: unquote('"\\eae0"');
$ni-cc-mc: unquote('"\\eae0"');
$ni-discover: unquote('"\\ea1a"');
$ni-cc-discover: unquote('"\\ea1a"');
$ni-visa: unquote('"\\ec3d"');
$ni-cc-visa: unquote('"\\ec3d"');
$ni-cc-paypal: unquote('"\\eb27"');
$ni-cc-stripe: unquote('"\\e9ad"');
$ni-amazon-pay: unquote('"\\e916"');
$ni-amazon-pay-fill: unquote('"\\e915"');
$ni-google-pay: unquote('"\\ea77"');
$ni-google-pay-fill: unquote('"\\ea76"');
$ni-apple-pay: unquote('"\\e91d"');
$ni-apple-pay-fill: unquote('"\\e91c"');
$ni-angular: unquote('"\\e91a"');
$ni-react: unquote('"\\eb4d"');
$ni-laravel: unquote('"\\eab7"');
$ni-html5: unquote('"\\ea9e"');
$ni-css3-fill: unquote('"\\ea09"');
$ni-css3: unquote('"\\ea0a"');
$ni-js: unquote('"\\eab0"');
$ni-php: unquote('"\\eb2d"');
$ni-python: unquote('"\\eb49"');
$ni-bootstrap: unquote('"\\e974"');
$ni-ebay: unquote('"\\ea27"');
$ni-google-wallet: unquote('"\\ea79"');
$ni-google-drive: unquote('"\\ea75"');
$ni-google-play-store: unquote('"\\ea78"');
$ni-android: unquote('"\\e919"');
$ni-blogger-fill: unquote('"\\e96b"');
$ni-blogger: unquote('"\\e96c"');
$ni-hangout: unquote('"\\ea8d"');
$ni-apple-store: unquote('"\\e91f"');
$ni-apple-store-ios: unquote('"\\e91e"');
$ni-stripe: unquote('"\\ebd7"');
$ni-apple: unquote('"\\e920"');
$ni-microsoft: unquote('"\\eaf0"');
$ni-windows: unquote('"\\ec4c"');
$ni-amazon: unquote('"\\e917"');
$ni-paypal-alt: unquote('"\\eb28"');
$ni-airbnb: unquote('"\\e908"');
$ni-adobe: unquote('"\\e907"');
$ni-mailchimp: unquote('"\\ead8"');
$ni-dropbox: unquote('"\\ea26"');
$ni-digital-ocean: unquote('"\\ea19"');
$ni-slack: unquote('"\\ebb5"');
$ni-slack-hash: unquote('"\\ebb4"');
$ni-stack-overflow: unquote('"\\ebcb"');
$ni-soundcloud: unquote('"\\ebc0"');
$ni-blackberry: unquote('"\\e966"');
$ni-spotify: unquote('"\\ebc6"');
$ni-kickstarter: unquote('"\\eab1"');
$ni-houzz: unquote('"\\ea9d"');
$ni-vine: unquote('"\\ec3c"');
$ni-yelp: unquote('"\\ec4f"');
$ni-yoast: unquote('"\\ec50"');
$ni-envato: unquote('"\\ea2f"');
$ni-wordpress: unquote('"\\ec4e"');
$ni-wp: unquote('"\\ec4e"');
$ni-wordpress-fill: unquote('"\\ec4d"');
$ni-elementor: unquote('"\\ea2c"');
$ni-joomla: unquote('"\\eaaf"');
$ni-megento: unquote('"\\eae3"');
$ni-git: unquote('"\\ea70"');
$ni-github: unquote('"\\ea73"');
$ni-github-round: unquote('"\\ea72"');
$ni-github-circle: unquote('"\\ea71"');
$ni-dribbble: unquote('"\\ea25"');
$ni-dribbble-round: unquote('"\\ea24"');
$ni-behance: unquote('"\\e961"');
$ni-behance-fill: unquote('"\\e960"');
$ni-flickr: unquote('"\\ea5d"');
$ni-flickr-round: unquote('"\\ea5c"');
$ni-medium: unquote('"\\eae2"');
$ni-medium-round: unquote('"\\eae1"');
$ni-reddit: unquote('"\\eb50"');
$ni-reddit-round: unquote('"\\eb4f"');
$ni-reddit-circle: unquote('"\\eb4e"');
$ni-google: unquote('"\\ea7a"');
$ni-facebook-f: unquote('"\\ea3e"');
$ni-facebook-fill: unquote('"\\ea3f"');
$ni-facebook-circle: unquote('"\\ea3d"');
$ni-instagram: unquote('"\\eaab"');
$ni-instagram-round: unquote('"\\eaaa"');
$ni-linkedin: unquote('"\\eacb"');
$ni-linkedin-round: unquote('"\\eaca"');
$ni-twitter: unquote('"\\ec0f"');
$ni-twitter-round: unquote('"\\ec0e"');
$ni-pinterest: unquote('"\\eb34"');
$ni-pinterest-round: unquote('"\\eb33"');
$ni-pinterest-circle: unquote('"\\eb32"');
$ni-tumblr: unquote('"\\ec0d"');
$ni-tumblr-round: unquote('"\\ec0c"');
$ni-skype: unquote('"\\ebb3"');
$ni-viber: unquote('"\\ec36"');
$ni-whatsapp: unquote('"\\ec49"');
$ni-whatsapp-round: unquote('"\\ec48"');
$ni-snapchat: unquote('"\\ebb7"');
$ni-snapchat-fill: unquote('"\\ebb6"');
$ni-telegram: unquote('"\\ebec"');
$ni-telegram-circle: unquote('"\\ebeb"');
$ni-youtube-line: unquote('"\\ec52"');
$ni-youtube: unquote('"\\ec52"');
$ni-youtube-fill: unquote('"\\ec53"');
$ni-youtube-round: unquote('"\\ec51"');
$ni-vimeo: unquote('"\\ec3b"');
$ni-vimeo-fill: unquote('"\\ec3a"');

